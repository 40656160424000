<template>
  <div class="service">
    <div class="bigimg-wraper">
      <img src="/static/service/1.png" class="bigimg" alt="" />
      <div class="easy">
        <div class="easy-left">
          <div class="title">
            质 保 无 忧
          </div>
          <div class="text">多重质保，保修期高达8年<br />告别购车后顾之忧</div>
          <div class="btn" @click="toDetail">
            查 看 详 情
          </div>
        </div>
        <div class="easy-right">
          <div
            class="easy-right-list"
            v-for="(item, index) in easy"
            :key="index"
          >
            <img :src="item.icon" alt="" />
            <div class="easy-right-list-text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      <img src="/static/service/2.png" class="bigimg" alt="" />
      <div class="car-service">
        <div class="car-service-left">
          <div class="title">
            车 主 服 务
          </div>
          <div class="text">
            管家式车主服务，
            <br />守护用户与悦心车共度的美好时光
          </div>
        </div>
        <div class="car-service-right">
          <div class="car-service-right-lists">
            <div class="car-service-right-list" v-for="item,index in carService" :key="index">
              <img :src="item.icon" alt="" />
              <div class="easy-right-list-text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/static/service/3.png" class="bigimg" alt="" />
      <div class="guide">
        <div class="guide-left">
          <div class="title">
            购 车 指 南
          </div>
          <div class="text">
            在线选车，预约看车，<br>畅享一站式购车体验
          </div>
        </div>
        <div class="guide-right">
          <div class="guide-right-list" v-for="item,index in guide" :key="index">
            <div class="guide-right-list-top">
              <img :src="item.icon" alt="">
              <div class="title">
                {{item.title}}
              </div>
            </div>
            <div class="guide-right-list-bot" v-if="item.tel || item.text">
              <span v-if="item.tel">{{item.tel}}</span>
              {{item.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseurl: "https://www.yuexinche.com",
      easy: [
        { icon: "/static/service/easy1.png", text: "30天全车无上限保修" },
        { icon: "/static/service/easy2.png", text: "60天严重质量问题退车" },
        { icon: "/static/service/easy3.png", text: "90天易损件保修" },
        { icon: "/static/service/easy4.png", text: "延长保修服务" },
      ],
      carService:[
        { icon: "/static/service/service1.png", text: "终身专属客服" },
        { icon: "/static/service/service2.png", text: "机场代泊服务" },
        { icon: "/static/service/service3.png", text: "协助出险理赔" },
        { icon: "/static/service/service4.png", text: "代办保养" },
        { icon: "/static/service/service5.png", text: "代办年检服务" },
        { icon: "/static/service/service6.png", text: "24H道路救援" },
        { icon: "/static/service/service7.png", text: "保养期间代步车" },
        { icon: "/static/service/service8.png", text: "限号期间代步车" },
        { icon: "/static/service/service9.png", text: "租车体验" },
      ],
      guide:[
        { icon: "/static/service/guide1.png", text: "通过官网/悦心车小程序（微信搜索悦心车）在线选车。" ,title:'步骤1：线上选车',tel:''},
        { icon: "/static/service/guide2.png", text: "联系专业服务顾问或点击官网/小程序预约顾问了解详情并预约看车时间。" ,title:'步骤2：预约看车',tel:'400-997-5955'},
        { icon: "/static/service/guide3.png", text: "按约定时间前往悦心车线下门店，专业服务顾问与产品顾问全程陪同看车。" ,title:'步骤3：线下看车',tel:''},
        { icon: "/static/service/guide4.png", text: "确认车辆产品后签署购车合同" ,title:'步骤4：签署合同',tel:''},
        { icon: "/static/service/guide5.png", text: "签订购车合同后，专业服务顾问将全程协助办理交易过户手续。" ,title:'步骤5：交易过户',tel:''},
        { icon: "/static/service/guide6.png", text: "" ,title:'步骤6：开走爱车',tel:''},
      ]
    };
  },
  methods: {
    toDetail(){
      this.$router.push(
        {
          path:"/serviceDetail"
        }
      )
    }
  },
  mounted() {},
};
</script>

<style lang="less" src="./index.less" scoped></style>
